.ty-calendar-slider {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-x: scroll;
    max-height: 86px;
}

.ty-calendar-slider::-webkit-scrollbar {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ty-calendar-slider::-webkit-scrollbar:horizontal {
    height: 11px;
}

.ty-calendar-slider::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #ccc; /* should match background, can't be transparent */
    background-color: #ccc;
}

.ty-calendar-slider::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #F6F7F9;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px #F6F7F9;
    -webkit-border-radius: 4px;
}

.ty-calendar-date {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ty-calendar-day-week {
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.ty-calendar-day {
    padding-right: 10px;
    position: relative;
    cursor: pointer;
}

.ty-calendar-date:last-child {
    padding-right: 10px;
}

.ty-calendar-date:last-child > .ty-calendar-day-week, .ty-calendar-date:last-child > .ty-calendar-day {
    padding-right: 0;
}

.day-selected {
    color: white;
    z-index: 1001;
    position: relative;
}

.day-selected-active {
    background-color: #71CBF4;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -4px;
}

.disabled-day {
    color: #ccc;
    cursor: not-allowed;
}