.logo-list{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
}

.logo-list li{
    min-width: 140px; 
    min-height: 70px;
    width: 140px; 
    height: 70px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    background-origin: content-box;
}


ul.simple-list{
    list-style: none;
    margin-top: 20px;
}

ul.simple-list li{
    margin-bottom: 20px;
}

.sub-title{
    padding: 0 10vw;
}

.gnr-top-img{
    width: calc(100% - 20px);
    max-width: 800px;
    margin: 10px;
}
